import './Layout.css';
import Navbar from './Navbar.js';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <div className="App">
      
      <Navbar />
      <Outlet/>
    </div>
  );
}

export default Layout;
