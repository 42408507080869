import React, { useState } from 'react';
import { useWindowSize } from './Resize';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const NavbarContainer = styled.nav`
    padding:1rem;
    align-items:center;
    border-bottom: 1px dashed #3333cc;
    background-color:#FFF;
    color: #3333cc;
    display: flex;
    flex-direction: row;
    display:flex;
    justify-content:space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    margin-bottom:1rem;
`;

const Logo = styled.div`
    font-size: 1.5rem;
    font-family: 'Sans Serif';
`;

const NavLink = styled(Link)`
    a {
        color: #3333cc;
        text-decoration: none;
        margin-right: 1rem;
    }
    a:hover {
        color:#cc33cc;
    }
`;

const MenuIcon = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: block;
        cursor: pointer;
    }
`;

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    // const [width, height] = useWindowSize();
    const [width] = useWindowSize();
    return (
        <NavbarContainer>
            <Logo>IAN ELLIS</Logo>
            <MenuIcon onClick={() => setIsOpen(!isOpen)}>
                {!isOpen && (<div>▼MENU▼</div>)}
            </MenuIcon>

            {(width > 768 || isOpen) && (
                <div>
                    <MenuIcon onClick={() => setIsOpen(!isOpen)}>
                        <div>▲MENU▲</div>
                    </MenuIcon>
                    <NavLink>
                        <Link to="/">Home</Link>
                        <Link to="/visual">Visual</Link>
                        <Link to="/audio">Audio</Link>
                        <Link to="/miscellanious">Miscellanious</Link>
                    </NavLink>
                </div>
            )}
        </NavbarContainer>
    );
}

export default Navbar;