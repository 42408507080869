import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useWindowSize } from './Resize';

const ButtonOuterContainer = styled.span`
    position: absolute;
    justify-content: center;
    left:0px;
    right:0px;
    bottom:1rem;
    align-items: center;
    
`;

const Cell = styled.span`
    margin-right: .7em;
    margin-bottom: -.1em;
    font-weight: 900;
    cursor: crosshair;
    
`;

const ControlButton = styled.button`
    
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5px;
    background-color: ${props => !props.activated ? "rgb(0,0,"+props.themeColor+")" : "rgb(0,0,"+255-props.themeColor+")"};
    border: 0px solid rgb(0,0,0);
    color: ${props => props.activated ? "black" : "white" };
    font-weight:600;
    &:active {
        opacity:0.3;
    }
`;

const Pattern = styled.div`
    max-width: 100vw;
    /* display: flex; */
`;

const Row = styled.div`
    max-width: 100vw;
    flex-wrap: wrap;
    /* display: flex; */
    @media (max-width: 768px) {
            font-family: cursive;
    }
`;

let cells = [];
let patternWidth = 29;
if (window.innerWidth<=768) patternWidth = 10;
let patternHeight = 29;
let patternAverage = 0;
let rising = false;
let chaosMode = false;
let firstTime = true;
let freeze = false;
let refreshInterval;
let homePatternUpdate;
let flip = true;

const PatternContainer = styled.span`
    text-align: center;
    max-width: 100vw;
`;

let patternArray = Array(patternHeight).fill().map(() => Array(patternWidth));
for (let i = 0; i<patternHeight; i++) {
    for (let j = 0; j<patternWidth; j++) {
        patternArray[i][j] = 0;
    }
}

function updatePattern() {
    
    let im;
    let ip;
    let jm;
    let jp;
    let replacementArray = Array(patternHeight).fill().map(() => Array(patternWidth).fill(0));
    for (let i = 0; i<patternHeight; i++) {
        for (let j = 0; j<patternWidth; j++) {
            if (i===0) {
                im = patternArray[i][j];
                ip = patternArray[i+1][j];
            }
            else if (i===patternHeight-1) {
                im = patternArray[i-1][j];
                ip = patternArray[i][j];
            }
            else {
                im = patternArray[i-1][j];
                ip = patternArray[i+1][j];
            }
            if (j===0) {
                jm = patternArray[i][j];
                jp = patternArray[i][j+1];
            }
            else if (j===patternWidth-1) {
                jm = patternArray[i][j-1];
                jp = patternArray[i][j];
            }
            else {
                jm = patternArray[i][j-1];
                jp = patternArray[i][j+1];
            }
            
            // 
            if (Math.floor(Math.random()*2500)===42) replacementArray[i][j] = chaosMode ? ((patternArray[i][j]+Math.floor(Math.random()*3000)) % 1000)-1500 : rising ? (patternArray[i][j]+Math.ceil(Math.random()*1000)) % 1000 : (patternArray[i][j]+Math.floor(Math.random()*1000) % 1000);
            else replacementArray[i][j] = rising ? Math.ceil((im + ip + jm + jp + patternArray[i][j]*8)/12) : Math.floor((im + ip + jm + jp + patternArray[i][j]*6)/10);
        }        
    };
    patternArray = replacementArray;
    patternAverage = Math.floor(patternArray.reduce((sum,a) => sum + a.reduce((sum,b) => sum +b, 0), 0)/(patternHeight*patternWidth));
}
const risingToggle = () => {
    rising = !rising;
    chaosMode = false;
}

const chaosToggle = () => {
    chaosMode = !chaosMode;
    rising = false;
}

const freezeToggle = () => {
    if (freeze === false) {
        clearInterval(refreshInterval);
    }
    else {
        refreshInterval = setInterval(() => {
            updatePattern();
            homePatternUpdate(new Boolean(!flip));
            // firstTime = false;

            // TODO: move this into styling
            for (let x of document.getElementsByTagName('span')) x.setAttribute('style', 'color:rgb(0,0,'+x.innerHTML+');background-color:rgb(0,0,'+x.innerHTML+');')
            return () => clearInterval(refreshInterval);
        }, 90 );
    };
    freeze = !freeze;
}

const resetPattern = () => {
    patternArray = Array(patternHeight).fill().map(() => Array(patternWidth).fill(0));
    patternAverage = 0;
}

const HomePattern = () => {
    const [isActive, setIsActive] = useState(new Boolean(false));
    homePatternUpdate = setIsActive;
    const [width, height] = useWindowSize();
    useEffect( () => {
        for (let c in document.getElementsByClassName("cell")) cells.push(c);

        if (firstTime) refreshInterval = setInterval(() => {
            updatePattern();
            setIsActive(new Boolean(!isActive));
            firstTime = false;

            // TODO: move this into styling
            for (let x of document.getElementsByTagName('span')) x.setAttribute('style', 'color:rgb(0,0,'+x.innerHTML+');background-color:rgb(0,0,'+x.innerHTML+');')
            return () => clearInterval(refreshInterval);
        }, 90 )}, []);
        
    return(
        <PatternContainer>
            <Pattern>
                {
                    patternArray.map((row, x) => (
                        <Row key={x}>
                            {row.map((cell, j) => (
                                <Cell class="cell" key={j} on>{cell}</Cell>
                            ))}
                        </Row>
                    ))
                }
                <br></br>
            </Pattern>
            <ButtonOuterContainer>
               <ControlButton themeColor={patternAverage} activated={rising} onClick={() => {risingToggle()}}>{rising ? "Rise" : "Rise"}</ControlButton>
               <ControlButton themeColor={patternAverage} activated={chaosMode} onClick={() => {chaosToggle()}}>{chaosMode ? "Chaos" : "Chaos"}</ControlButton> 
               <ControlButton themeColor={patternAverage} activated={false} onClick={() => {resetPattern()}}>Reset</ControlButton>
               <ControlButton themeColor={patternAverage} activated={freeze} onClick={() => {freezeToggle()}}>Freeze</ControlButton>
               <ControlButton themeColor={patternAverage} activated={false}>{patternAverage}</ControlButton>
               {/* <input value={patternWidth} onChange={e => {
                    freezeToggle();
                    patternWidth=e.target.value;
                    resetPattern();
                    freezeToggle();
               }} type="number" min="1" max="40"></input>
               <input value={patternHeight} onChange={e => {
                    freezeToggle();
                    patternHeight=e.target.value;
                    resetPattern();
                    freezeToggle();
               }} type="number" min="1" max="40"></input> */}
            </ButtonOuterContainer>
        </PatternContainer>
    );
}

export default HomePattern;

